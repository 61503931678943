import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
import { lato } from '@styles/fonts'

const useStyles = makeStyles()((theme) => ({
  boxWrapper: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: theme.spacing(4),
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: theme.spacing(10, 0),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: theme.spacing(1),
    },
  },
  desc: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontFamily: lato.style.fontFamily,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'start',
    width: '60%',

    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      display: 'none',
    },
  },
  titleBox: {
    position: 'absolute',
    bottom: 0,
    marginLeft: '1rem',
    marginBottom: '1.4rem',
  },
  hideOnSm: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  viewOnSm: {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      marginTop: theme.spacing(8),
      a: {
        width: '100% !important',
      },
    },
  },
  title: {
    padding: '0',
    margin: '0',
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: '800',
    fontSize: '72px',
    lineHeight: '83px',
    textTransform: 'uppercase',
    color: ' #000000',
    textAlign: 'start',
    [theme.breakpoints.down('md')]: {
      fontSize: '34px',
      lineHeight: '18px',
    },
  },
  img: {
    width: '100% !important',
    objectFit: 'cover',
    borderRadius: '24px',
    height: '350px',
    img: {
      border: '10px solid red',
    },
  },

  imgMobile: {
    width: '100% !important',
    objectFit: 'cover',
    borderRadius: '24px',
    transition: 'all 0.4s ease',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '10px',
    },
  },
  brandBox: {
    position: 'relative',
    height: 'auto',
    margin: theme.spacing(0, 2.1),
    transition: 'all 0.4s ease',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),

      marginTop: theme.spacing(4),
    },
  },

  outerBox: {
    cursor: 'pointer',
    position: 'absolute',
    margin: 'auto',
    top: '47%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: '78px',
    height: '78px',
    background: 'rgba(28, 28, 28, 0.38)',
    transition: 'all 2s ease',
  },
  innerBox: {
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: '62px',
    height: '62px',
    background: 'rgba(28, 28, 28, 0.38)',
    paddingTop: '15px',
    paddingLeft: '5px',
  },
  avatar: {
    width: '69px',
    height: '69px',
    [theme.breakpoints.down('md')]: {
      width: '44px',
      height: '44px',
    },
  },
  brandInfo: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },

  gradientEffect: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    background: 'linear-gradient(0deg, #101010 14%, transparent)',
    height: '160px',
    width: '100%',
    borderRadius: '24px',
  },
  videoDesc: {
    padding: '0',
    margin: '0',
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: '800',
    fontSize: '24px',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    color: ' #ffffff',
    textAlign: 'start',
    paddingLeft: '1rem',
    // textShadow: '0px 0px 5px rgba(0, 0, 0, 1)',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  modalContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalInnerContainer: {
    width: '100%',
    maxWidth: theme.maxWidth,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      width: '100%',
    },
  },
}))

export default useStyles
