/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import useStyles from './Styles'
import Image, { StaticImageData } from 'next/image'
import ImgOne from 'src/assets/images/dtlOriginals/brands-1.jpg'
import ImgTwo from 'src/assets/images/dtlOriginals/brands-2.jpg'
import ImgThree from 'src/assets/images/dtlOriginals/brands-3.jpg'
import ImgFour from 'src/assets/images/dtlOriginals/brands-4.jpg'
import Play from 'src/assets/svgs/icons/play'
import clsx from 'clsx'
import Tag from 'src/components/shared/atoms/Tag/Tag'
import { Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import { useGetAllCollabVideosQuery } from 'src/store/ApiCall/brandCollabAPI'
import YoutubeVideoPlayer from 'src/components/shared/modules/youtubeVideoPlayer'
import Modal from 'src/components/shared/modules/modal'
import { getCookie } from 'cookies-next'

interface BoxesInterface {
  size: string
  id: number
  img: StaticImageData
}

interface Props {
  headerContent: {
    heading: string
    description: string
    button: { content: string; href: string }
  }
}

function BrandsCollab({ headerContent }: Props) {
  const { classes } = useStyles()
  const [width, setWidth] = React.useState(1024)
  const isBrowser = typeof window === 'object' ? true : false
  const lang = getCookie('lang')
  const [openPlayer, setOpenPlayer] = React.useState(false)
  const [switchToHQ, setSwitchToHQ] = React.useState<boolean>(false)
  const [currentVideo, setCurrentVideo] = React.useState('')
  const [brandCollabData, setBrandCollabData] = React.useState<any>()

  useEffect(() => {
    if (window) setWidth(window.innerWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const router = useRouter()

  const { data: brandCollabRes } = useGetAllCollabVideosQuery()

  const [boxes, setBoxes] = React.useState<BoxesInterface[]>([
    { id: 0, size: '50%', img: ImgOne },
    { id: 1, size: '30%', img: ImgTwo },
    { id: 2, size: '20%', img: ImgThree },
    { id: 4, size: '10%', img: ImgFour },
  ])

  const changeWidth = (id: number) => {
    const widths = ['30%', '20%', '10%']
    let index = 0
    const updatedBoxes = boxes.map((box) => {
      if (box.id === id) {
        return { ...box, size: '50%' }
      } else if (index < widths.length) {
        const currentWidth = widths[index]
        index++
        return { ...box, size: currentWidth }
      }
      return { id: 0, size: '50%', img: ImgOne }
    })

    setBoxes(updatedBoxes)
  }

  const changeHieght = (id: number) => {
    const hieghts = ['97px', '67px', '33px']
    let index = 0
    const updatedBoxes = boxes.map((box) => {
      if (box.id === id) {
        return { ...box, size: '290px' }
      } else if (index < hieghts.length) {
        const currentHieght = hieghts[index]
        index++
        return { ...box, size: currentHieght }
      }
      return { id: 0, size: '290px', img: ImgOne }
    })

    setBoxes(updatedBoxes)
  }
  const resetMethod = () => {
    setBoxes([
      { id: 0, size: '50%', img: ImgOne },
      { id: 1, size: '30%', img: ImgTwo },
      { id: 2, size: '20%', img: ImgThree },
      { id: 4, size: '10%', img: ImgFour },
    ])
  }
  useEffect(() => {
    if (width < 680) {
      setBoxes([
        { id: 0, size: '290px', img: ImgOne },
        { id: 1, size: '200px', img: ImgTwo },
        { id: 2, size: '100px', img: ImgThree },
        { id: 4, size: '100px', img: ImgFour },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width])

  React.useEffect(() => {
    if (window) {
      window?.addEventListener('resize', function () {
        setWidth(window.innerWidth)
      })
    }
  }, [])

  useEffect(() => {
    if (width < 680) {
      setBoxes([
        { id: 0, size: '290px', img: ImgOne },
        { id: 1, size: '200px', img: ImgTwo },
        { id: 2, size: '100px', img: ImgThree },
        { id: 4, size: '100px', img: ImgFour },
      ])
    } else {
      setBoxes([
        { id: 0, size: '50%', img: ImgOne },
        { id: 1, size: '30%', img: ImgTwo },
        { id: 2, size: '20%', img: ImgThree },
        { id: 4, size: '10%', img: ImgFour },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!brandCollabRes || brandCollabRes.data.length == 0) return
    setBrandCollabData(brandCollabRes?.data)
  }, [brandCollabRes])

  // return a loader or skeleton
  if (!brandCollabData || brandCollabData.length == 0) return <></>
  return (
    <Box>
      <div className={classes.header}>
        <div style={{ width: '100%' }}>
          <Typography className={classes.title}>
            {headerContent.heading}
          </Typography>
          <Typography className={classes.desc}>
            {headerContent.description}
          </Typography>
        </div>

        <Box className={classes.hideOnSm}>
          <Tag
            id={1}
            content={headerContent.button.content}
            color="black"
            background="white"
            style={{
              whiteSpace: 'nowrap',
              fontSize: '22px',
            }}
            onClick={() =>
              headerContent.button.href
                ? router.push(headerContent.button.href)
                : '#'
            }
          />
        </Box>
      </div>
      <div className={classes.boxWrapper} onMouseLeave={resetMethod}>
        {boxes.map((box, idx) =>
          width > 680 ? (
            <div
              key={idx}
              className={clsx(classes.brandBox)}
              style={{ width: box.size }}
              onMouseOver={() => {
                changeWidth(box.id)
              }}
            >
              <div className={classes.gradientEffect}></div>
              {box.size === '50%' && (
                <div className={classes.outerBox}>
                  <div className={classes.innerBox}>
                    <Play
                      onClickHandler={() => {
                        setOpenPlayer(!openPlayer)
                        setCurrentVideo(
                          brandCollabData[idx]?.attributes.url || ''
                        )
                      }}
                    />
                  </div>
                </div>
              )}
              {box.size === '50%' && (
                <div className={classes.titleBox}>
                  <Box className={classes.brandInfo}>
                    <Avatar
                      className={classes.avatar}
                      src={
                        brandCollabData[idx]?.attributes.brandLogo?.data
                          ?.attributes?.url
                          ? brandCollabData[idx]?.attributes.brandLogo?.data
                              .attributes.url
                          : ''
                      }
                    ></Avatar>
                    <Box>
                      <Typography className={classes.videoDesc} pl={4}>
                        {brandCollabData[idx]?.attributes.title || ''}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              )}
              <Image
                src={
                  !switchToHQ
                    ? `https://img.youtube.com/vi/${brandCollabData[idx]?.attributes.videoID}/maxresdefault.jpg`
                    : `https://img.youtube.com/vi/${brandCollabData[idx]?.attributes.videoID}/hqdefault.jpg`
                }
                alt="image"
                width={500}
                height={500}
                onError={() => setSwitchToHQ(true)}
                className={classes.img}
              />
            </div>
          ) : (
            <div
              key={idx}
              className={clsx(classes.brandBox)}
              onMouseOver={() => {
                changeHieght(box.id)
              }}
            >
              {box.size == '290px' && (
                <div className={classes.outerBox}>
                  <div className={classes.innerBox}>
                    <Play
                      onClickHandler={() => {
                        setOpenPlayer(true)
                        setCurrentVideo(
                          brandCollabData[idx]?.attributes.url || ''
                        )
                      }}
                    />
                  </div>
                </div>
              )}
              {box.size === '290px' && (
                <div className={classes.titleBox}>
                  <Box className={classes.brandInfo}>
                    <Avatar
                      className={classes.avatar}
                      src={
                        brandCollabData[idx]?.attributes.brandLogo?.data
                          .attributes.url || ''
                      }
                    ></Avatar>
                    <Box>
                      <Typography className={classes.videoDesc} pl={4}>
                        {brandCollabData[idx]?.attributes.title || ''}
                      </Typography>
                    </Box>
                  </Box>
                </div>
              )}
              <Image
                src={
                  !switchToHQ
                    ? `https://img.youtube.com/vi/${brandCollabData[idx]?.attributes.videoID}/maxresdefault.jpg`
                    : `https://img.youtube.com/vi/${brandCollabData[idx]?.attributes.videoID}/hqdefault.jpg`
                }
                alt="image"
                width={500}
                height={500}
                aria-label={brandCollabData[idx]?.attributes.title || ''}
                style={{ height: box.size }}
                className={classes.imgMobile}
              />
            </div>
          )
        )}
      </div>

      <Box>
        <Box className={classes.viewOnSm}>
          <Tag
            id={2}
            content={isBrowser && lang === 'En' ? 'WATCH ALL' : 'VER TODOS'}
            color="black"
            background="white"
            style={{ width: '100%' }}
            onClick={() => router.push('/campaign')}
          />
        </Box>
      </Box>

      {openPlayer ? (
        <Modal closeHandler={() => setOpenPlayer(!openPlayer)}>
          <Box className={classes.modalContainer}>
            <Box className={classes.modalInnerContainer}>
              {currentVideo ? <YoutubeVideoPlayer src={currentVideo} /> : null}
            </Box>
          </Box>
        </Modal>
      ) : null}
    </Box>
  )
}

export default BrandsCollab
