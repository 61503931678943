/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import ReactPlayer from 'react-player'

interface YoutubeVideoPlayerType {
  src: string
  styles?: { [key: string]: unknown }
}

const YoutubeVideoPlayer: React.FC<YoutubeVideoPlayerType> = ({
  src,
  styles,
}: YoutubeVideoPlayerType) => {
  return (
    <ReactPlayer
      url={src}
      playing
      loop
      controls
      muted
      stopOnUnmount
      width="900px"
      height="650px"
      style={styles}
    />
  )
}

export default YoutubeVideoPlayer
